@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,600;1,100&display=swap);
/* @import url("https://fonts.googleapis.com/css?family=Roboto&display=swap"); */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    /* scroll-behavior: smooth; */
    direction: ltr;
}

html::selection {
    color: #00ad9f;
}

body {
    margin: 0;
    padding: 0;
    /* font-family: "Roboto", sans-serif; */
    font-family: Montserrat, Helvetica, Arial, Lucida, sans-serif;
    
}
ul{
    list-style-type: none;
}
video {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    /* position: absolute; */
    z-index: -20;
    /* margin-top: 7em; */
    /* border-radius: 0% 0% 50% 50% / 0% 0% 7% 7%;
    border-bottom: 20px solid #0c7ba8; */
}

.wrapper11 {
    width: 100%;
    height: 99vh;
    overflow: hidden;
    /* border-radius: 0 0 30vw 0;
    overflow: hidden; */
    /* border-color: #0c7ba8;
    border-right-width: 1vw;
    border-right-style: solid; */
    z-index: 10;
    /* box-shadow: 20px 0px 0px 0px #1a9eb3; */
    /* border-radius: 0% 0% 50% 50% / 0% 0% 9% 9%; */
  
}

.wrapper11 .thisCotegory {
    display: block;
    position: absolute;
    top: 58%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 2em;
    text-shadow: 2px 2px #203d45;
    width: 80%;
}

.wrapper11 .thisTitle {
    display: block;
    position: absolute;
    top: 58%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
    color: rgb(255, 255, 255);
    /* font-size: 1.5em; */
    text-shadow: 2px 2px #203d45;
    /* padding-left: 50px; */
    width:65%;

   
}
.wrapper11 .thisTitle .titleBorder{
 position: absolute;
 top: -50px;
 left: 0;
 width: 200px;
 height: 300px;
 border: 12px solid transparent;
 -webkit-border-image: -webkit-gradient(linear, left top, right top, from(#095498),color-stop(#0c7ba8), to(#1a9eb3));
 -webkit-border-image: linear-gradient(to right, #095498,#0c7ba8, #1a9eb3);
         border-image: -webkit-gradient(linear, left top, right top, from(#095498),color-stop(#0c7ba8), to(#1a9eb3));
         border-image: linear-gradient(to right, #095498,#0c7ba8, #1a9eb3);
 border-image-slice: 1;
 border-right: none;
 

}
.wrapper11 .thisTitle h1 {
    font-size: 3em;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.wrapper11 .thisTitle p {
    font-size: 1.2em;
    color:#eee
}
.wrapper11::before {
    width: 100%;
    height: 99vh;
    overflow: hidden;
    position: absolute;
    content: "";
     /* background: #115999;
    opacity: 0.3; */
    /* background: linear-gradient(90deg, #0d599a, #36a6b8);
    opacity: .7; */
    background: rgba(0, 0, 0, 0.7);
    /* border-radius: 0 0 30vw 0; */
    /* border-bottom: 8px solid transparent;
    border-image: linear-gradient(to right, #095498,#0c7ba8, #1a9eb3);
    border-image-slice: 1; */
    /* border-radius: 0% 0% 50% 50% / 0% 0% 12% 12%; */
   
    border-bottom: 8px solid transparent;
    margin-bottom: 200px;
    -webkit-border-image: -webkit-gradient(linear, left top, right top, from(#095498),color-stop(#0c7ba8), to(#1a9eb3));
    -webkit-border-image: linear-gradient(to right, #095498,#0c7ba8, #1a9eb3);
            border-image: -webkit-gradient(linear, left top, right top, from(#095498),color-stop(#0c7ba8), to(#1a9eb3));
            border-image: linear-gradient(to right, #095498,#0c7ba8, #1a9eb3);
    border-image-slice: 1;
}
.wavy{
    position: absolute;
    left: 0;
    top: 0;
    width: 500px;
}

.wrapper11 .mainButton button{
    background: -webkit-gradient(linear, left top, right top, from(#095498),color-stop(#0c7ba8), to(#1a9eb3));
    background: linear-gradient(to right, #095498,#0c7ba8, #1a9eb3);
    color:#fff;
    padding: 15px 30px;
    border: 0;
    margin-top: 50px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: bold;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    position: relative;
}
.wrapper11 .mainButton button:hover{
    background: rgb(212, 211, 211);
    color:#1a9eb3;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
   
    
}
.wow {
    visibility: hidden;
  }
.wrapper11 .mainButton button:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: -1.25rem;
    min-width: 1.25rem;
    height: 2px;
    background:-webkit-gradient(linear, right top, left top, from(#095498),color-stop(#0c7ba8), to(#1a9eb3));
    background:linear-gradient(to left, #095498,#0c7ba8, #1a9eb3);
}
.wrapper11 .mainButton button:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: -1.25rem;
    min-width: 1.25rem;
    height: 3px;
    background:-webkit-gradient(linear, right top, left top, from(#095498),color-stop(#0c7ba8), to(#1a9eb3));
    background:linear-gradient(to left, #095498,#0c7ba8, #1a9eb3);
}
.wrapper11 svg{
    position: absolute;
    left: 0;
    bottom: -120px;
    display: none;
   
}
.wrapper11 svg path{
    fill:#fff !important
}

/* Nav */

#nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: white; */
    padding: 0em 7.7em;
    position: fixed;
    width: 100%;
    /* min-height: 10vh; */
    z-index: 9999;
    /* box-shadow: 0px 1px 7px 0px #ece9e9; */
    top: 0;
    flex-direction: column;
    
   /* box-shadow: 0 1px 1px rgba(255, 255, 255, 0.3); */
}

#flex2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}


/* nav in responsive Drop menu*/

#navDropMenu1 {
    text-align: center;
    display: none;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
}

.transform {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

#space {
    display: none;
    content: "";
    position: relative;
    top: 10vh;
    height: 34vh;
}


/* menu Icon */

#navMenuIcon {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 15px;
    height: 50px;
    margin: 0.8em;
    padding: 0.67em 2em;
    cursor: pointer;
    background: -webkit-gradient(linear, left top, right top, from(#095498),color-stop(#0c7ba8), to(#1a9eb3));
    background: linear-gradient(to right, #095498,#0c7ba8, #1a9eb3);
    border-radius: 5px;
}

#lineTop,
#lineMiddle,
#lineBottom {
    background: #fff;
    height: 0.4vh;
    width: 35px;
    margin: -1.8em;
}

#lineTop,
#lineBottom {
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}


/* end  menu Icon*/

.navLinks {
    margin: 0;
    display: flex;
    align-items: center;
    list-style: none;
}

.navLinks li a {
    text-decoration: none;
}

.navLinks li a p {
    /* color: #4d4d4d; */
    color:#fff;
    /* padding: 1em; */
    margin: 0.6em 1em;
    font-size: 1em;
    cursor: pointer;
    text-decoration: none;
    /* border-bottom: 4px solid #ffffff;
    border-top: 4px solid #ffffff; */
    padding: 20px 0;
    margin: 0 20px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-transform: uppercase;
    font-weight: 600;
}

.navLinks li a p:hover {
    color: #1a9eb3;
    border-bottom: 4px solid #1a9eb3;
    border-top: 4px solid #1a9eb3;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 6px 0;
}
.navLinks .changeLanguage{
    background: transparent;
    color:#fff;
    border:0;
    font-size: 1em;
    font-weight: 600;
    margin: 0 20px;
}

.navDropUl {
    width: 100%;
    list-style: none;
}

.navDropUl a{
    text-decoration: none;
    color: #4d4d4d;
    background: transparent;
    border:0;
    /* color:#fff */
}
.navDropUl .changeLanguage{
    background: transparent;
    color:#4d4d4d;
    border:0;
    
}

.navDropUl li {
    font-size: 1.3em;
    text-decoration: none;
    padding: 0.4em 1em;
    margin: 1em;
    cursor: pointer;
}

.navDropUl li:hover {
    background: #e4dfdf6e;
}

#navLogo {
    width: 10em;
    padding: 0.4em 0;
}
 
.navbar-fixed-top.scrolled {
    background-color: #fff !important;
    -webkit-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
    box-shadow: 0 0 30px rgba(7,51,84,0.17)
  }
  .navbar-fixed-top.scrolled .navLinks li a p ,.navbar-fixed-top.scrolled li .changeLanguage {
    color: #4d4d4d;
}
.navbar-fixed-top.colored {
    background-color: #fff !important;
    -webkit-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
    box-shadow: 0 0 30px rgba(7,51,84,0.17)
  }
  .navbar-fixed-top.colored .navLinks li a p,.navbar-fixed-top.colored li .changeLanguage {
    color: #4d4d4d;
}


.headingLeftCopyrights {
    /* justify-content: center; */
    padding-left: 4em;
}

.headingLeftCopyrights h2 {
    font-size: 2.5em;
    text-align: left;
    line-height: normal;
    /* letter-spacing: 0.08em; */
    color: #3f3d56;
    font-weight: 600;
    padding-top: 3em;
}
.heading img{
    height:80px;
    opacity: 0.7;
    position: relative;
    top:20px
}


/* ABOUT */

.headingLeft {
    /* display: flex;
    flex-direction: column; */
    /* justify-content: center; */
    padding-left: 4em;
}

.headingLeft h2 {
    font-size: 3em;
    text-align: left;
    line-height: normal;
    letter-spacing: 0.08em;
    color: #3f3d56;
    font-weight: 600;
    padding-top: 1em;
    /* padding-left: 1.5em; */
    position: relative;
    margin-bottom: 1em;
}
.headingLeft h2::after{
    /* content: "";
    position: absolute;
 top: 1em;
 left: 0;
 width: 50px;
 height: 50px;
 border: 10px solid transparent;
 border-image: linear-gradient(to right, #095498,#0c7ba8, #1a9eb3);
 border-image-slice: 1;
 border-right: none; */
}
.headingLeft img{
    height:80px;
    opacity: 0.7;
    position: relative;
    top:20px
}


.borderBottom {
    /* border-bottom-style: solid;
    width: 9%; */
    /* text-align: center; */
    /* align-self: center; */
    /* width: 116px;
    border-width: 12px;
    padding-top: 1em;
    border-bottom-color: #00ad9f; */
}

.heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.heading h2 {
    font-size: 3em;
    text-align: center;
    line-height: normal;
    letter-spacing: 0.08em;
    color:#403f52;
    font-weight: 600;
    /* padding-top: 3em; */
    position: relative;
}
.heading::after{
    /* content: "";
    position: absolute;
 top: 0;
 left: 30%;
 width: 50px;
 height: 50px;
 border: 10px solid transparent;
 border-image: linear-gradient(to right, #095498,#0c7ba8, #1a9eb3);
 border-image-slice: 1;
 border-right: none; */
}
.borderBottom {
    /* border-bottom-style: solid;
    width: 9%;
    text-align: center;
    align-self: center;
    width: 116px;
    border-width: 12px;
    padding-top: 1em;
    border-bottom-color: #00ad9f; */
}

.about {
    /* font-family: "Roboto", sans-serif; */
    /* padding-top: 4em; */
    /* background-color: #f7f7f7; */
    background-color: #fff;
    padding: 120px 0;
    position: relative;
    margin-top: 20px;
}
.aboutContent{
    background-color: #ffffff;
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    box-shadow: 0px 10px 60px 0px rgba(1,33,92,0.1);
    display: flex;
    flex-direction: row;
    flex-flow: row;
    width: 90%;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding: 10px 0 10px;
   
}
.circleImg{
    opacity: 0.5;
    height: 120px;
    position: absolute;
    left: 35px;
    bottom: 60px;
    display: block;
    z-index: 1;
}

.aboutText {
    padding-left: 4em;
    /* padding-right: 4em; */
}

.aboutText p {
    padding-bottom: 1em;
    color:#555;
    line-height: 1.7em;
    /* padding-bottom: 3em; */
}

.homeContacUsImg {
    /* background-image: url("https://i0.wp.com/norcheminc.com/img/norchem-distributors-products.jpg"); */
    /* background-attachment: fixed; */
    /* background-image: url(../media/clients-logo/shariqa-fixed.png); */
    /* background-size: contain;
    width: 50%;
    background-position-x: right;
    background-repeat: no-repeat; */

    position: relative;
    top:0px;
    right:0;
    /* bottom: 119px; */
    /* height:500px; */
    /* box-shadow: 1px 1px 9px #cecece; */
    max-width: 650px;
    width:45%;
    /* padding: 50px 50px 10px 10px; */
    z-index: 5;
}
.homeContacUsImg:before {
    /* content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ddf0f4;
    clip-path: polygon(58% 0, 65% 6%, 94% 6%, 94% 38%, 100% 43%, 100% 0);
    left: -35px;
    bottom: -28px;
    transform: rotate(180deg);
    z-index: 1; */
}
.homeContacUsImg:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-clip-path: polygon(58% 0, 65% 6%, 94% 6%, 94% 38%, 100% 43%, 100% 100%, 0 100%, 0 0);
            clip-path: polygon(58% 0, 65% 6%, 94% 6%, 94% 38%, 100% 43%, 100% 100%, 0 100%, 0 0);
    background: #f7f7f7;
    z-index: -1;
    box-shadow: 1px 1px 9px #cecece;
    border-radius: 20px;
}
.about-right {
    /* content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background:linear-gradient(120deg, #095498,#0c7ba8, #1a9eb3);;
    clip-path: polygon(58% 0, 65% 6%, 94% 6%, 94% 38%, 100% 43%, 100% 0);
    left: 10px;
    top: -10px;
    z-index: 1; */
}

.homeContacUsImg img{
    width: 100%;
    height: 100%;
    display: block;
    /* border-radius:20px; */
    /* box-shadow: 1px 1px 9px #cecece; */
}
/* HERO HEADER */

.downButton img {
    width: 4%;
    outline: none;
}

.downButton {
    position: relative;
    top: 5em;
    -webkit-animation: downMove 2s infinite ease;
            animation: downMove 2s infinite ease;
}

@-webkit-keyframes downMove {
    0% {
        top: 3em;
    }
    50% {
        top: 5em;
    }
    100% {
        top: 3em;
    }
}

@keyframes downMove {
    0% {
        top: 3em;
    }
    50% {
        top: 5em;
    }
    100% {
        top: 3em;
    }
}


/* SERVICES */

#services {
    /* background-color: #f7f8fc ; */
    background-color: rgba(36, 114, 186, 0.04);
    border-radius: 0 26vw 26vw 0;
    
}

.services {
    /* font-family: "Roboto", sans-serif; */
    /* font-weight: normal; */
    margin: 0 5rem;
    padding: 120px 0;
   
}

.wholeServices {
    display: flex;
    justify-content: space-between;
    padding: 3em 0;
    flex-flow: row;
    position: relative;
    top: 2em;
}

.singleService {
    background-color: #f7f7f7;
    width: 32%;
    position: relative;
    /* bottom: 119px;
    left: 50%;
    transform: translateX(-50%); */
    /* box-shadow: 1px 1px 9px #cecece; */
    box-shadow: 0px 10px 80px -10px rgba(44,54,92,0.2);
    padding: 20px 0;
    z-index: 5;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom:7px solid #fff;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}
.singleService:hover{
    border-bottom:7px solid #1a9eb3;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}
.singleService:before {
    /* content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background:linear-gradient(to left, #095498,#0c7ba8, #1a9eb3);;
    clip-path: polygon(58% 0, 65% 6%, 94% 6%, 94% 38%, 100% 43%, 100% 0);
    left: -35px;
    bottom: -28px;
    transform: rotate(180deg);
    z-index: 1; */
}
.singleService:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-clip-path: polygon(58% 0, 65% 6%, 94% 6%, 94% 38%, 100% 43%, 100% 100%, 0 100%, 0 0);
            clip-path: polygon(58% 0, 65% 6%, 94% 6%, 94% 38%, 100% 43%, 100% 100%, 0 100%, 0 0);
    background: #fff;
    z-index: -1;
    box-shadow: 1px 1px 9px #cecece;
}
.hero__slideContent--right {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background:-webkit-gradient(linear, left top, right top, from(#095498),color-stop(#0c7ba8), to(#1a9eb3));
    background:linear-gradient(90deg, #095498,#0c7ba8, #1a9eb3);;
    -webkit-clip-path: polygon(58% 0, 65% 6%, 94% 6%, 94% 38%, 100% 43%, 100% 0);;
            clip-path: polygon(58% 0, 65% 6%, 94% 6%, 94% 38%, 100% 43%, 100% 0);
    left: 10px;
    top: -10px;
    z-index: 1;
}
.serviceImg{
    width:100px;
   
    margin: 15px auto;
   
    /* padding: 20px; */
}
.singleService img {
    width: 100%;
    display: block;
    
}

.serviceText {
    padding: 1em;
}

.serviceText h3 {
    /* padding: 1em 1em; */
    color: #3d5975;
    padding-bottom: 1em;
    font-weight: 600;
}

.serviceText p {
    /* padding: 0em 1em; */
    line-height: 1.7;
    color: #898989;
    font-weight: 300;
    font-size: 1em;
}

.serviceText h3 {
    line-height: 1.1em;
    font-size: 1.4em;
    text-align: center;
}


/* YEARS */

.years {
    /* font-family: "Roboto", sans-serif; */
    /* padding-top: 4em; */
    /* background-color: #00ad9f; */
    /* background: linear-gradient(#0d5899, #00ad9f); */
    background: -webkit-gradient(linear, left top, right top, from(#095498),color-stop(#0c7ba8), to(#1a9eb3));
    background: linear-gradient(to right, #095498,#0c7ba8, #1a9eb3);
    padding: 1.1em 11em;
    margin-top: 2em;
   
}

.wholeYears {
    display: flex;
    justify-content: space-around;
    margin: 1em;
    flex-direction: row;
    padding: 2em 0em;
}

.counterDiv {
    text-align: center;
    color: #deedfd;
    width: 100%;
    align-self: center;
}

.counterDiv span {
    font-size: 2em;
    font-weight: 600;
    color:#fff;
}

.counterDiv h3 {
    font-size: 1.3em;
    font-weight: normal;
}


/*This is only for Cotegory*/

.yearsText {
    text-align: center;
    font-size: 1.9em;
    color: #fff;
    padding: 0em;
    padding-top: 1em;
}

.singleYear {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.borderRight {
    border-right-style: solid;
    height: 68%;
    border-right-color: #ffffff;
    padding-right: 0em;
    display: block;
}

.singleYear:nth-child(3) .borderRight {
    border-style: none;
    text-align: center;
    color: #3d5975;
}


/* CLIENTS */

.clients {
    /* font-family: "Roboto", sans-serif; */
    /* background:url(../media/arrows/bg.svg) no-repeat ,linear-gradient(transparent,transparent);; */
    
    padding: 100px 0;
    border-radius: 15vw 0 0 0;
    background-color: rgba(36, 114, 186, 0.04);
    /* margin: 250px 0; */
}

.wholeClients {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.singleClient {
    display: flex;
    justify-content: center;
    width: 16%;
    padding-top: 4em;
    padding-bottom: 5em;
}

.singleClient img {
    width: 86%;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    height: 100%;
}

.singleClient img:hover {
    width: 86%;
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}


/* CONTACT */

.contact {
    /* font-family: "Roboto", sans-serif; */
    background-color: rgba(36, 114, 186, 0.04);
    text-align: center;
    padding: 80px 0 0;
    
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; */
}
.divider{
    background: url(/static/media/wavebg.93e4b610.svg) no-repeat;
    height: 300px;
    background-size: cover;
}

.map {
    padding-top: 5em;
}

.thisContactSection {
    display: flex;
    justify-content: center;
    /* padding: 3em 0; */
    width: 85%;
    flex-flow: row;
    background-color: #ffffff;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0px 90px 160px 0px rgba(0,0,0,0.06);
    margin-top:5em;
    margin: 5em auto;
}

.contactInfo {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    text-align: center;
    width: 50%;
    margin-right: 4em;
    padding: 30px 0;
    /* background: url("../media/arrows/cleaning.jpg") no-repeat;
    background-size: cover;
    height:500px; */
    /* background: linear-gradient(to right, #095498,#0c7ba8, #1a9eb3); */
}

.contactInfo2 {
    display: flex;
    flex-flow: column;
    /* justify-content: space-between; */
    text-align: left;
    width: 50%;
    padding: 30px 0;
    /* padding-right: 5em; */
}
.follow{
    margin-top: 50px;
}
.follow ul {
    display: flex;
    /* flex: 0 0 50%;  */
    
}
.follow ul li img{
  width:3em;
  margin-right: 20px;
  margin-top: 20px;
}
.follow ul li {
   -webkit-transition: all .4s ease-in-out;
   transition: all .4s ease-in-out;
  }
.follow ul li:hover{
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}

.contactText {
    padding-bottom: 2em;
}

.contactText h2 {
    line-height: 3.1em;
    font-size: 1em;
    color: #1e2d3b;
}

.contactText p {
    /* padding: 0em 1em; */
    line-height: 1.6em;
    color: rgb(76, 87, 97);
}

.contactInfo2 .contactText:nth-child(1) p:nth-child(2) {
    padding-bottom: 1.5em;
}

.contactForm {
    /* padding-bottom: 2em; */
   
}

.contactForm h2 {
    line-height: 3.1em;
    font-size: 1.4em;
    color: #1e2d3b;
    text-align: left;
}

.contactForm p {
    /* padding: 0em 1em; */
    line-height: 1.6em;
    color: rgb(76, 87, 97);
    /* padding-bottom:1.5em; */
    padding: 1em 1em 1.5em 2em;
    text-align: left;
}

input,
textarea {
    width: 85%;
}

input{
    height: 3em;
    /* border-style: solid;
    border-color: #00ad9f;
    border-width: 1px; */
    border:0;
    /* padding-left: 1em; */
    font-size: 14px;
    padding: 16px;
    margin-bottom: 30px;
    background-color: #f8fafe;
    border-radius: 5px;
}

input:focus {
    border-color: #00ad9f;
}

textarea {
    border:0;
    resize: vertical;
    padding-left: 1em;
    padding-top: 1em;
    background-color: #f8fafe;
    border-radius: 5px;
}

.contactForm button {
    /* border-style: solid;
    background-color: transparent;
    color: black;
    padding: 0.5em 3em;
    float: right;
    transition: all 0.3s ease;
    border-color: #00ad9f;
    border-width: 7px; */
    background: -webkit-gradient(linear, left top, right top, from(#095498),color-stop(#0c7ba8), to(#1a9eb3));
    background: linear-gradient(to right, #095498,#0c7ba8, #1a9eb3);
    color: #fff;
    width:120px;
    height:40px;
    border: 0;
    margin-top: 30px;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 500;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    float: right;
    letter-spacing: 2px;
    box-shadow: 0px 10px 20px 0px rgba(62, 161, 241, 0.2);
    margin-right: 2em;
}

.contactForm button:hover {
    /* border-style: solid;
    background-color: #00ad9f;
    color: #fff;
    padding: 0.5em 3em;
    float: right;
    transition: all 0.3s ease;
    border-color: #00ad9f;
    border-width: 7px; */
    background: #ddf0f480;
    color:#1a9eb3;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}

.homeContactUs {
    display: flex;
    flex-direction: row;
    flex-flow: row;
    width: 100%;
}

.homecontactDiv {
    width: 55%;
}


/* PROJECTS */

#project {
    background-color: #fff;
}

.ideasWrapper1 {
    padding: 0em 0;
    padding-bottom: 5em;
   
}
.products_wraper{
    background:url(/static/media/bg.7c865317.svg) no-repeat ,-webkit-gradient(linear,left top, left bottom,from(transparent),to(transparent));
    background:url(/static/media/bg.7c865317.svg) no-repeat ,linear-gradient(transparent,transparent);
}

.ideas {
    display: flex;
    flex-direction: column;
    margin: 0em 9em -8em 9em;
    padding: 0em;
}

.ideas>h1 {
    margin: 0.3em 0;
    font-size: 2.8em;
    font-weight: lighter;
    color: #151417d4;
}

.ideas>p {
    width: 38em;
    font-size: 1.4em;
    color: #3b404fe0;
    margin-bottom: 1em;
}

.slider {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8em;
    height: 37em;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.sliderImg {
    width: 100%;
}

.sliderBtn {
    width: 50%;
}

.sliderBtn button {
    cursor: pointer;
    border-style: none;
    padding: 1.3em 3em;
    -webkit-filter: opacity(95%);
            filter: opacity(95%);
}

.sliderbtm1 {
    position: relative;
    top: -11em;
    width: 36%;
    left: 4em;
}

.sliderText {
    margin-bottom: -5em;
    width: 50%;
    font-size: 1.6em;
    padding: 0.5em;
    overflow: hidden;
    -webkit-filter: opacity(95%);
            filter: opacity(95%);
}

.sliderText1 {
    padding: 2em;
    background-color: white;
    font-size: 1.2em;
}

.maxLines {
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 5em;
    line-height: 1.6em;
    font-size: 1em;
}

.sliderText p {
    margin: 0 0.5em;
}

.sliderLeftArrow {
    width: 2.5em;
    height: 2em;
}

.sliderRightArrow {
    width: 2.5em;
    height: 2em;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.latestStories {
    display: flex;
    flex-flow: row wrap;
    padding: 4.2em 0em;
    justify-content: space-between;
    margin: 0 4em;
}

.lastestStory {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: row;
  
}

.storyLink {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: #4d4d4d;
    display: flex;
    position: relative;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    margin: 10px 20px 0 20px;
    /* flex-grow: 1; */
    /* height: 100px; */
    width: calc(100% * (1/4) - 40px);
}

.latestStorySection {
    padding: 100px 0;
    /* margin: 0em 9em 0 9em; */
}

.latestStorieshead1 {
    width: 100%;
    text-align: center;
}

.latestStorieshead1>h1 {
    font-weight: lighter;
    font-size: 3em;
    padding-top: 1em;
    /* margin: 2em 0em 0.1em 2.6em; */
    color: #151417d4;
}

.story {
    /* width: 13em; */
    margin: 2.5em 0;
    flex: 1 0 21%;
    /* margin: 5px; */
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 10px 80px -10px rgba(44,54,92,0.2);
    position: relative;
}
.story:hover{
    /* box-shadow: none; */
    
}
.storyLink:hover{
    -webkit-transform: translateY(-10px) !important;
            transform: translateY(-10px) !important;
}
.story:hover:before, .story:hover:after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background:  #095498;
    opacity: 0.5;
    border-radius: 10px;
    z-index: -1;
    -webkit-animation: 1s clockwise infinite;
            animation: 1s clockwise infinite;
    
  }
  
  .story:hover:after {
    background: #1a9eb3;
    -webkit-animation: 2s counterclockwise infinite;
            animation: 2s counterclockwise infinite;
  }
  
  @-webkit-keyframes clockwise {
    0% {
      top: -5px;
      left: 0;
    }
    12% {
      top: -2px;
      left: 2px;
    }
    25% {
      top: 0;
      left: 5px;    
    }
    37% {
      top: 2px;
      left: 2px;
    }
    50% {
      top: 5px;
      left: 0;    
    }
    62% {
      top: 2px;
      left: -2px;
    }
    75% {
      top: 0;
      left: -5px;
    }
    87% {
      top: -2px;
      left: -2px;
    }
    100% {
      top: -5px;
      left: 0;    
    }
  }
  
  @keyframes clockwise {
    0% {
      top: -5px;
      left: 0;
    }
    12% {
      top: -2px;
      left: 2px;
    }
    25% {
      top: 0;
      left: 5px;    
    }
    37% {
      top: 2px;
      left: 2px;
    }
    50% {
      top: 5px;
      left: 0;    
    }
    62% {
      top: 2px;
      left: -2px;
    }
    75% {
      top: 0;
      left: -5px;
    }
    87% {
      top: -2px;
      left: -2px;
    }
    100% {
      top: -5px;
      left: 0;    
    }
  }
  
  @-webkit-keyframes counterclockwise {
    0% {
      top: -5px;
      right: 0;
    }
    12% {
      top: -2px;
      right: 2px;
    }
    25% {
      top: 0;
      right: 5px;    
    }
    37% {
      top: 2px;
      right: 2px;
    }
    50% {
      top: 5px;
      right: 0;    
    }
    62% {
      top: 2px;
      right: -2px;
    }
    75% {
      top: 0;
      right: -5px;
    }
    87% {
      top: -2px;
      right: -2px;
    }
    100% {
      top: -5px;
      right: 0;    
    }
  }
  
  @keyframes counterclockwise {
    0% {
      top: -5px;
      right: 0;
    }
    12% {
      top: -2px;
      right: 2px;
    }
    25% {
      top: 0;
      right: 5px;    
    }
    37% {
      top: 2px;
      right: 2px;
    }
    50% {
      top: 5px;
      right: 0;    
    }
    62% {
      top: 2px;
      right: -2px;
    }
    75% {
      top: 0;
      right: -5px;
    }
    87% {
      top: -2px;
      right: -2px;
    }
    100% {
      top: -5px;
      right: 0;    
    }
  }

.story h2 {
    font-weight: lighter;
    padding: 0;
}
.story h3 {
    font-weight: lighter;
   color:#777
}

.story h2+h2 {
    font-style: italic;
    padding-bottom: 1.6em;
    line-height: 1em;
}

.storyimg {
    /* height: 12em; */
    height:250px;
    width:250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 0.8em;
}
.storyimg img{
    height:100%;
    width:100%;
    display: block;
}

.morebtn {
    /* border-style: solid;
    background-color: transparent;
    color: black;
    padding: 0.5em 3em; */
    /* float: right; */
    /* transition: all 0.3s ease;
    border-color: #00ad9f;
    border-width: 7px;
    text-align: center; */
    background: -webkit-gradient(linear, left top, right top, from(#095498),color-stop(#0c7ba8), to(#1a9eb3));
    background: linear-gradient(to right, #095498,#0c7ba8, #1a9eb3);
    color: #fff;
    min-width:150px;
    height:50px;
    border: 0;
    margin-top: 50px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: bold;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    position: relative;
}

.morebtn:hover {
   
    background: #ddf0f480;
    color: #1a9eb3;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    
}
.checkMore{
    padding: 0 20px;
    display: none;
    cursor: pointer;
    line-height: 50px;
}

.morebtnDisc {
    /* border-style: solid;
    background-color: transparent;
    color: #4d4d4d;
    padding: 0.2em 2em; */
    /* float: right; */
    /* transition: all 0.3s ease;
    border-color: #00ad9f;
    border-width: 3px;*/
    text-align: center; 
    display: inline-block;
    background: transparent;
    color: #1a9eb3;
    width:200px;
    height:50px;
    line-height: 50px;
    margin-top: 20px;
    font-size: 16px !important;
    font-weight: normal;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    border: 3px solid transparent;
    -webkit-border-image:-webkit-gradient(linear, left top, right top, from(#095498),color-stop(#0c7ba8), to(#1a9eb3));
    -webkit-border-image:linear-gradient(to right, #095498,#0c7ba8, #1a9eb3);
            border-image:-webkit-gradient(linear, left top, right top, from(#095498),color-stop(#0c7ba8), to(#1a9eb3));
            border-image:linear-gradient(to right, #095498,#0c7ba8, #1a9eb3);
    border-image-slice: 1;
   float: right;
    
}

.morebtnDisc:hover {
    /* border-style: solid;
    background-color: #00ad9f;
    color: #fff;
    padding: 0.2em 3em; */
    /* float: right; */
    /* transition: all 0.3s ease;
    border-color: #00ad9f;
    border-width: 7px;
    text-align: center; */
    border: 3px solid #ddf0f480;
    background: #ddf0f480;
    color:#1a9eb3
}
.social{
    border-top:1px solid #777;
    margin-top: 50px;
    overflow: hidden;
}
.social span{
 display: inline-block;
 color:#000;
 margin-right: 5em;
}

.articleWrapper {
    margin: 7em 7em 0em 6em;
}

.article {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.articleHeader {
    width: 50%;
    margin-top: 1em;
}

.articleHeader h1 {
    font-size: 2em;
    font-weight: bold;
    color: #169db1;
    margin: 1em auto;
}

.singleDiv {
    display: flex;
    margin-bottom: 1.5em;
}

.singleDiv>p {
    font-size: 0.9em;
}

.detailDiv {
    width: 9em;
    /* margin-bottom: 1.5em; */
}

.detailDiv p {
    color: grey;
    margin-right: 5em;
    width: 100%;
    font-weight: 600;
}

.articleHeader a {
    font-size: 1.5em;
    text-decoration: none;
}

.articleImg {
    width: 40%;
    height: 100%;
    position: relative;
    /* top: 2em;
  right: 4em; */
    /* background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
}
.articleImg img{
    height:100%;
    width:100%;
}

.articleParagraph {
    display: block;
    width: 74%;
    padding: 4em 7.7em;
    margin-top: -1.5em;
    background-color: #fbf3e9;
    font-size: 1em;
    color: #3b404fe0;
}

.articleParagraph h2 {
    font-weight: 300;
}

.BackNextBtn {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 2em;
}

.backBtn {
    background-color: transparent;
    border-style: none;
    -webkit-filter: opacity(95%);
            filter: opacity(95%);
    font-size: 1.5em;
    padding: 1em;
}

.nextBtn {
    background-color: transparent;
    border-style: none;
    padding: 1em;
    -webkit-filter: opacity(95%);
            filter: opacity(95%);
    font-size: 1.5em;
}

.articleBtnsMobileUp,
.articleBtnsMobileDown {
    display: none;
}

button {
    outline: none;
    cursor: pointer;
}

.sliderBtn1 button {
    background-color: white;
    padding: 1em 2em;
    border-style: none;
}

.sliderBtn1 button:hover {
    background-color: whitesmoke;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.backBtn,
.nextBtn,
.backBtnUp,
.nextBtnUp,
.backBtnDown,
.nextBtnDown {
    text-decoration: none;
    color: #0d0d0d;
    cursor: pointer;
    outline: none;
}

.articleRightArrow {
    width: 2.2em;
    height: 0.7em;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.articleLeftArrow {
    width: 2.2em;
    height: 0.7em;
}

.articleParagraph p {
    font-size: 1.4em;
}

.articleSocialIcon {
    width: 1em;
    margin: 1em 0.6em 0 0;
}


/* End Ideas Section*/


/* Error Page */

._404Error {
    background-color: #ddf2f5ad;
    display: flex;
    justify-content: center;
    height: auto;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 0;
}

._404 {
    display: flex;
    font-size: 7em;
    justify-content: space-around;
    padding-top: 0.8em;
    text-shadow: rgb(0, 0, 0) 3px 5px 0px;
    color: white;
    width: 56%;
    align-items: center;
}

._404 img {
    width: 63%;
    padding-left: 0.3em;
}

.oops {
    font-size: 2em;
    position: relative;
    top: -5%;
    padding-bottom: 2em;
    padding-top: 1em;
}

.oops p {
    font-size: 1.4rem;
}

.oops h2 {
    font-size: 2rem;
}

.oops button {
    background-color: transparent;
    border-style: solid;
    padding: 1em 2em;
    border-color: #159cb0;
    font-weight: 700;
    margin-top: 1em;
    border-width: 7px;
    cursor: pointer;
}


/* End Error Page */


/* Footer */


/* Footer */

footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 25vh !important;
    background: white;
    /* padding: 3em 7.7em; */
    background-image: url(/static/media/water.0c9d79bd.png);
    background-repeat: no-repeat;
    flex-direction: column;
    background-size: 18% !important;
}

.footerLinks {
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: 4em;
    padding-top: 2em;
    font-weight: 550;
}

.footerInner1 a {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #4c4c4b;
    text-decoration: none;
}

.footerInner2 a {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #4c4c4b;
    text-decoration: none;
}

.footerLinks li {
    margin: 1.5em 1em;
}

.copyrights {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style: none;
    width: 100%;
    position: relative;
    bottom: -20px;
    font-weight: 600;
}

.copyrights a {
    text-decoration: none;
    color: #4c4c4b;
    padding: 0em 1em;
}

.footerLinks li a {
    text-decoration: none;
    font-size: 1.2em;
    color: #8a8989;
    padding: 1em;
}

.footerStyle {
    margin: 0;
    padding: 0.3em 0.3em;
    text-align: right;
    font-size: 1.2em;
}

.footerAddress {
    font-size: 1em;
    /* color: #8a8989; */
    color: #a9a9ad;
    font-weight: 100;
    line-height: 1.9em;
    margin: 0em 1.5em;
}

#footerLogo {
    width: 42%;
    height: 50%;
    padding-bottom: 1.2em;
    display: none;
}


/* footer svg */

.Icon {
    background: -webkit-gradient(linear, left top, right top, from(#095498),color-stop(#0c7ba8), to(#1a9eb3));
    background: linear-gradient(to right, #095498,#0c7ba8, #1a9eb3);;
    padding: 0em;
    margin: 0em;
    width: 3em;
    height: 3em;
    line-height: 4em;
    text-align:center;
    border-radius: 50%;
}


.mail {
    /* -webkit-mask: url("../media/footerIcons/mail.svg") no-repeat center;
    mask: url("../media/footerIcons/mail.svg") no-repeat center; */
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.phone {
    /* -webkit-mask: url("../media/footerIcons/phone.svg") no-repeat center;
    mask: url("../media/footerIcons/phone.svg") no-repeat center; */
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.location {
    /* -webkit-mask: url("../media/footerIcons/location.svg") no-repeat center;
    mask: url("../media/footerIcons/location.svg") no-repeat center; */
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.mailBox {
    /* -webkit-mask: url("../media/footerIcons/mailbox.svg") no-repeat center;
    mask: url("../media/footerIcons/mailbox.svg") no-repeat center; */
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.mail:hover,
.phone:hover,
.location:hover,
.mailBox:hover {
    background-color: #00ad9f;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}


/* Special media queries for navbar */

@media only screen and (min-width: 861px) and (max-width: 1024px) {
    #navDropMenu1 {
        display: none !important;
    }
    #space {
        height: 0;
    }
}

@media only screen and (min-width: 660px) and (max-width: 861px) {
    #navDropMenu1 {
        display: none;
    }
    #space {
        height: 25vh;
    }
}


/* TERMS */

.wholeTerms {
    padding: 3em 7em;
}

.wholeTerms h3 {
    color: #4c4c4b;
    padding-bottom: 2em;
}

.wholeTerms p {
    padding-bottom: 2em;
    color: #4c4c4b;
}


/* PRIVACY */

.wholePrivacy {
    padding: 3em 7em;
}

.wholePrivacy h3 {
    color: #4c4c4b;
    padding-bottom: 2em;
}

.wholePrivacy p {
    padding-bottom: 2em;
    color: #4c4c4b;
}

.wholePrivacy p:nth-child(1),
.wholePrivacy p:nth-child(4),
.wholePrivacy p:nth-child(10),
.wholePrivacy p:nth-child(16) {
    padding-bottom: 2em;
    color: #4c4c4b;
    font-size: 1.17em;
    font-weight: bold;
}

#serPara {
    position: relative;
    top: 0em;
    content: "";
}

.wholeOther {
    display: flex;
    justify-content: space-around;
    margin: 5em;
    /* align-items: center; */
    padding-top: 3em;
    background-color: #ddf0f480;
    padding-bottom: 3em;
    margin-top: 0em;
    box-shadow: 0 0 3px 0 rgb(197, 195, 194);
    border-radius: 20px;
    flex-direction: row;
}

.wholeOther ul {
    list-style: none;
}

.wholeOtherReverse {
    display: flex;
    justify-content: space-evenly;
    margin: 5em;
    /* align-items: center; */
    padding-top: 3em;
    background-color: #ddf0f480;
    padding-bottom: 3em;
    margin-top: 0em;
    box-shadow: 0 0 3px 0 rgb(197, 195, 194);
    border-radius: 20px;
    flex-direction: row;
}

.wholeOtherReverse ul {
    list-style: none;
}

.singleOther {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(20% - 8px);
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 1em;
}

.singleOtherBottom {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(40% - 8px);
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.singleOtherBig {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(45% - 8px);
    display: flex;
    justify-content: space-between;
    align-items: end;
    flex-direction: row;
    padding: 1em;
}

.singleOtherBig ul {
    align-self: flex-start;
    padding-right: 1.3em;
}

.singleOther:nth-child(3) .borderRight {
    visibility: hidden;
}

.singleOtherBottom:nth-child(2) .borderRight {
    visibility: hidden;
}

.singleOther:nth-child(2) .borderRight {
    visibility: hidden;
}

.singleOther1 {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(20% - 8px);
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 1em;
}

.singleOther1:nth-child(4) .borderRight {
    visibility: hidden;
}

.preImage {
    display: flex;
    justify-content: center;
    padding-top: 5em;
}

.preImage img {
    width: 30em;
    position: relative;
    z-index: -1;
}

.singleOther h3,
.singleOther1 h3,
.singleOtherBig h3,
.singleOtherBottom h3 {
    color: #169db1;
    padding-bottom: 0.5em;
    width: 13em;
}

.storyLink:nth-child(3) div .story .storyimg,
.storyLink:nth-child(5) div .story .storyimg {
    width: 72%;
    position: relative;
    right: -20px;
}
/* Min media queries */

@media (min-width: 1539px) {
    .articleImg {
        width: 35%;
        height: 846px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .articleHeader {
        width: 50em;
        font-size: 1.5em;
        margin-top: 1em;
    }
    .ideas>h1 {
        font-size: 2.5em;
    }
    .ideas>p {
        font-size: 1.4em;
        width: 100%;
        line-height: 1.6em;
    }
    .slider {
        width: 100%;
        height: 50em;
        background-position-y: -17em;
    }
    .sliderBtn {
        width: 35%;
    }
    .sliderBtn button {
        cursor: pointer;
        width: 50%;
        box-shadow: rgba(117, 116, 116, 0.517);
        font-size: 1.3em;
        padding: 1.1em;
        -webkit-filter: opacity(90%);
                filter: opacity(90%);
        background-color: whitesmoke;
    }
    .sliderbtm1 {
        position: relative;
        top: -26em;
        width: 39%;
        left: 8em;
    }
    .sliderText {
        background-color: whitesmoke;
        order: 1;
        font-size: 1.6em;
        -webkit-filter: opacity(90%);
                filter: opacity(90%);
        width: 100%;
    }
    .latestStories {
        justify-content: space-around;
        margin: 0 6.2em;
        padding: 0.5em 0;
    }
    .latestStorieshead1>h1 {
        font-size: 2.5em;
        margin: 0 3.2em;
    }
    .story {
        /* width: 55em;
        margin: 2em -5.5em; */
        cursor: pointer;
    }
    .storyimg {
        /* width: 100%;
        height: 40em; */
    }
    .story h1 {
        font-size: 2.1em;
    }
    .story h2 {
        font-size: 1.8em;
    }
    .morebtn {
        margin: 2em auto;
        font-size: 1.6em;
    }
}

@media (min-width: 2000px) {
    .maxLines {
        display: block;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 5em;
        line-height: 1.6em;
        font-size: 2em;
    }
    .sliderbtm1 {
        position: relative;
        top: -16em;
        width: 30%;
        left: 16em;
    }
}

@media screen and (min-width: 1089px) {
    #navMenuIcon {
        display: none !important;
    }
    #navDropMenu1 {
        display: none;
    }
    #space {
        display: none;
    }
}

@media screen and (min-width: 1024px) {
    .singleOtherBottom {
        flex-basis: calc(21% - 8px);
    }
    .wholeTerms {
        padding: 3em 4em;
    }
    .wholePrivacy {
        padding: 3em 4em;
    }
    footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 40vh;
        background: white;
        padding: 3em 7.7em;
        background-image: url(/static/media/water.0c9d79bd.png);
        background-repeat: no-repeat;
        flex-direction: column;
        background-size: 28%;
        background-position-y: 0em;
    }
    .footerInner1 {
        /* padding-right: 1em; */
    }
    .articleWrapper {
        margin-top: 7em;
    }
}


/* End Min media queries */


/* Max media queries */

@media (max-width: 1440px) {
    .wholeYears {
        display: flex;
        justify-content: space-around;
        margin: 1em;
        flex-direction: row;
        padding: 2em 0em;
    }
    ._404 {
        display: flex;
        font-size: 7em;
        justify-content: space-around;
        padding-top: 1.8em;
        text-shadow: rgb(0, 0, 0) 0px 0px 10px;
        color: white;
        width: 25%;
    }
    .heading h1 {
        font-size: 3.1em;
        text-align: center;
        line-height: normal;
        letter-spacing: 0.08em;
        color: #1e2d3b;
        font-weight: 600;
        padding-top: 3em;
    }
    .headingLeftCopyrights {
        /* justify-content: center; */
        padding-left: 4em;
        padding-top: 6em;
    }
    .maxLines {
        display: block;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 5em;
        line-height: 1.6em;
        font-size: 1.3em;
    }
    ._404 {
        display: flex;
        font-size: 7em;
        justify-content: space-around;
        padding-top: 1.8em;
        text-shadow: rgb(0, 0, 0) 0px 0px 10px;
        color: white;
        width: 25%;
    }
}

@media (max-width: 1100px) {
    .sliderbtm1 {
        position: relative;
        top: -9em;
        width: 36%;
        left: 4em;
    }
    .sliderText1 {
        padding: 2em;
        background-color: white;
        font-size: 1.2em;
        font-size: 1em;
    }
    .ideas>h1 {
        font-size: 2.5em;
    }
    .ideas>p {
        font-size: 1.4em;
        width: 100%;
        line-height: 1.6em;
    }
    .slider {
        width: 100%;
        height: 23em;
    }
    .sliderBtn {
        width: 40%;
    }
    .sliderBtn button {
        width: 50%;
        box-shadow: rgba(117, 116, 116, 0.517);
        font-size: 1.3em;
        padding: 1.1em;
        -webkit-filter: opacity(90%);
                filter: opacity(90%);
        background-color: whitesmoke;
    }
    .sliderbtm {
        display: flex;
        flex-direction: column;
        top: 64%;
        left: 5%;
        width: 60%;
    }
    .sliderText {
        background-color: whitesmoke;
        order: 1;
        font-size: 1.6em;
        -webkit-filter: opacity(90%);
                filter: opacity(90%);
        width: 100%;
    }
    .latestStories {
        flex-flow: column;
        justify-content: space-evenly;
        margin: 0 1.2em;
        padding: 2.5em 0;
    }
    .latestStorieshead1>h1 {
        font-size: 2.5em;
        margin: 1em 0em 0.5em 0.5em;
    }
    .story {
        /* width: 13em;
        margin: 2.5em 0;
        flex: 1 0 21%;
        margin: 5px; */
    }
    .storyLink{
        width: calc(100% * (1/2) - 40px);
    }
    .storyimg {
        width: 100%;
        height: 18em;
    }
    .story h1 {
        font-size: 2.1em;
    }
    .story h2 {
        font-size: 1.8em;
    }
    .morebtn {
        margin: 2em auto;
        font-size: 1.6em;
    }
    .sliderLeftArrow {
        width: 1.5em;
        height: 1.3em;
    }
    .sliderRightArrow {
        width: 1.5em;
        height: 1.3em;
    }
}

@media screen and (max-width: 1090px) {
    .wholeOtherReverse li {
        font-size: 0.8em;
    }
    .footerLinks {
        list-style: none;
        display: flex;
        flex-direction: row;
        width: 68%;
        justify-content: space-between;
    }
    .wholeOtherReverse li {
        font-size: 0.8em;
    }
    .footerLinks {
        list-style: none;
        display: flex;
        flex-direction: row;
        width: 68%;
        justify-content: space-between;
    }
    #nav {
        padding: 0em 2em;
        position: fixed;
        width: 100%;
        top: 0;
    }
    #navMenuIcon {
        display: none;
    }
    #navDropMenu1 {
        display: none;
    }
    #space {
        display: none;
    }
    .articleHeader {
        margin-left: 2.5em;
    }
    .articleHeader h1 {
        font-size: 2.5em;
        font-weight: lighter;
    }
    .articleHeader h2 {
        margin-left: 0.2em;
        font-size: 1.5em;
        font-weight: lighter;
    }
    .articleHeader a {
        font-size: 1.2em;
        padding-left: 0.3em;
        text-decoration: none;
        color: darkgreen;
    }
    .articleParagraph {
        padding: 3em 3em;
        width: 75%;
    }
    .backBtn {
        font-weight: lighter;
        font-size: 1.2em;
    }
    .nextBtn {
        font-weight: lighter;
        font-size: 1.2em;
    }
}

@media (max-width: 1024px) {
    .wholeOther {
        display: flex;
        justify-content: space-around;
        margin: 2em;
        /* align-items: center; */
        padding-top: 3em;
        background-color: #ddf0f480;
        padding-bottom: 3em;
        margin-top: 0em;
        box-shadow: 0 0 3px 0 rgb(197, 195, 194);
        border-radius: 20px;
    }
    .wholeOther li {
        font-size: 0.8em;
    }
    .wholeOtherReverse {
        display: flex;
        justify-content: space-around;
        margin: 2em;
        /* align-items: center; */
        padding-top: 3em;
        background-color: #ddf0f480;
        padding-bottom: 3em;
        margin-top: 0em;
        box-shadow: 0 0 3px 0 rgb(197, 195, 194);
        border-radius: 20px;
    }
    .wholeOtherReverse li {
        font-size: 0.8em;
    }
    .heading h1 {
        font-size: 3.1em;
        text-align: center;
        line-height: normal;
        letter-spacing: 0.08em;
        color: #1e2d3b;
        font-weight: 600;
        padding-top: 2.5em;
    }
    .footerLinks li {
        margin: 1em 1em;
    }
    .footerInner1 {
        padding-right: 4.5em;
    }
    footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: -moz-fit-content;
        min-height: -webkit-fit-content;
        min-height: fit-content;
        background: white;
        padding: 4em 7.7em;
        background-image: url(/static/media/water.0c9d79bd.png);
        background-repeat: no-repeat;
        background-size: 27%;
        background-position-y: 0em;
    }
    .footerLinks {
        list-style: none;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        padding: 0em 0em;
        font-size: 0.80em;
    }
    .aboutText {
        padding-left: 1em;
        padding-right: 2em;
    }
    .headingLeft {
        padding-left: 4em;
    }
    .headingLeftCopyrights {
        padding-left: 4em;
    }
    .ideasWrapper1 {
        margin-top: 0em;
    }
    .article {
        display: flex;
        flex-flow: nowrap;
        /* padding-top: 7em;  */
        justify-content: none;
    }
    .morebtn {
        margin: 2em auto;
        font-size: 1em;
    }
    .wrapper11 .thisTitle {
        display: block;
        position: absolute;
        top: 58%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        text-align: center;
        color: rgb(255, 255, 255);
        /* font-size: 1em; */
        text-shadow: 2px 2px #203d45;
    }
    
    .headingLeft h1 {
        font-size: 3.1em;
        text-align: left;
        line-height: normal;
        letter-spacing: 0.08em;
        color: #1e2d3b;
        font-weight: 600;
        padding-top: 2em;
    }
    .headingLeftCopyrights h1 {
        font-size: 3.1em;
        text-align: left;
        line-height: normal;
        letter-spacing: 0.08em;
        color: #1e2d3b;
        font-weight: 600;
        padding-top: 2em;
    }
    ._404 {
        display: flex;
        font-size: 7em;
        justify-content: space-around;
        padding-top: 1.8em;
        text-shadow: rgb(0, 0, 0) 0px 0px 10px;
        color: white;
        width: 43%;
    }
    ._404 img {
        width: 54%;
        padding-left: 0.3em;
    }
    .oops h2 {
        font-size: 2.5rem;
    }
    .contactHome #ContactTextarea {
        margin: 0.2em 0em;
    }
    .contactHome .contactInputandBtn {
        margin: 0.2em 0em;
    }
    .services {
        margin: 0 3em;
        padding: 2em 0;
    }
    /* Years */
    /* this is only the title ..simialr name years and year */
    .yearsText {
        text-align: center;
        font-size: 1.9em;
        color: #deedfd;
        padding: 0em;
        padding-top: 1em;
    }
    .counterDiv {
        width: 11em;
    }
    .borderRight {
        border-right-style: solid;
        height: 68%;
        border-right-color: #ffffff;
        padding-right: 2em;
        display: block;
    }
    .counterDiv h3 {
        font-size: 1.1em;
    }
    .singleYear {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .singleYear:nth-child(4) {
        margin: 0em;
    }
    iframe {
        width: 700px;
    }
    .articleWrapper {
        margin-left: 4em;
        margin-right: 4em;
    }
    .copyrights a {
        text-decoration: none;
        color: #4c4c4b;
        padding: 0em 1em;
        font-size: 1.5em;
    }
    .copyrights {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        list-style: none;
        width: 100%;
        position: relative;
        top: 32px;
    }
}

@media (max-width: 992px) {
    .wrapper11 .thisTitle h1 {
        font-size: 2.5em;
        margin-bottom: 20px;
        
    }
    .wrapper11 .thisTitle .titleBorder{
        width:100px;
        height: 350px;
    }
    .wholePrivacy {
        padding: 3em 3em;
    }
    .homecontactDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 40vh;
        background: white;
        width: 100%;
        flex-direction: column;
    }
    .headingLeft {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .headingLeft h1 {
        font-size: 3.1em;
        text-align: center;
        line-height: normal;
        letter-spacing: 0.08em;
        color: #1e2d3b;
        font-weight: 600;
        padding-top: 2em;
    }
    .headingLeftCopyrights {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .headingLeftCopyrights h1 {
        font-size: 3.1em;
        text-align: center;
        line-height: normal;
        letter-spacing: 0.08em;
        color: #1e2d3b;
        font-weight: 600;
        padding-top: 2em;
    }
    .borderBottom {
        border-bottom-style: solid;
        width: 9%;
        text-align: center;
        align-self: center;
        width: 116px;
        border-width: 12px;
        padding-top: 1em;
        border-bottom-color: #00ad9f;
    }
    .headingLeft {
        padding-left: 0em;
    }
    .headingLeftCopyrights {
        padding-left: 0em;
    }
    /*  */
    .latestStorieshead1>h1 {
        font-weight: lighter;
        font-size: 1.5em;
        padding: 0;
        margin: 2em 0em 0.1em 0.6em;
    }
    .article {
        flex-flow: column;
        align-items: center;
    }
    .articleWrapper {
        margin: 0em 3em 0em 3em;
    }
    .articlePage {
        display: flex;
        flex-direction: column;
    }
    .articleBtnsMobileUp {
        display: flex;
        justify-content: space-around;
        margin-top: 7em;
        margin-bottom: 1em;
    }
    .backBtnUp {
        background-color: transparent;
        border-style: none;
        padding: 1em 0em;
        -webkit-filter: opacity(95%);
                filter: opacity(95%);
        font-size: 1em;
        font-weight: bolder;
    }
    .nextBtnUp {
        background-color: transparent;
        border-style: none;
        padding: 1em 0em;
        -webkit-filter: opacity(95%);
                filter: opacity(95%);
        font-size: 1em;
        font-weight: bolder;
        display: block;
    }
    .articleBtnsMobileDown {
        order: 5;
        display: flex;
        justify-content: space-around;
        margin-top: 1em;
        margin-bottom: 1em;
    }
    .backBtnDown {
        background-color: transparent;
        border-style: none;
        padding: 1em 0em;
        -webkit-filter: opacity(95%);
                filter: opacity(95%);
        font-size: 1em;
        font-weight: bolder;
    }
    .nextBtnDown {
        background-color: transparent;
        border-style: none;
        padding: 1em 0em;
        -webkit-filter: opacity(95%);
                filter: opacity(95%);
        font-size: 1em;
        font-weight: bolder;
        display: block;
    }
    .backBtn,
    .nextBtn {
        display: none;
    }
    .articleImg {
        order: 2;
        width: 50%;
        height: 325px;
    }
    .articleHeader {
        order: 3;
        margin: 1em 0;
        margin-left: 1.5em;
    }
    .articleHeader h1 {
        font-size: 2em;
        font-weight: lighter;
    }
    .articleHeader h2 {
        margin-left: 0.2em;
        font-size: 1.2em;
        font-weight: lighter;
    }
    .articleHeader a {
        font-size: 1em;
        padding-left: 0.3em;
        text-decoration: none;
        color: darkgreen;
    }
    .articleParagraph {
        order: 4;
        display: block;
        background-color: antiquewhite;
        width: 100%;
        padding: 2em 2em;
        margin-top: 0.5em;
        line-height: 1.5;
    }
    .articleParagraph p {
        font-size: 1em;
    }
    /* For about img */
    .homeContacUsImg {
        display: none;
    }
    .ideas>p {
        font-size: 1.3em;
        width: 100%;
        line-height: 1.6em;
    }
}

@media screen and (max-width: 860px) {
    .singleOtherBottom {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: calc(44% - 8px);
        display: flex;
        justify-content: space-between;
        align-items: end;
    }
    .discImg {
        flex-basis: calc(20% - 8px);
        display: flex;
        justify-content: center;
    }
    .discImg img {
        width: 90%;
        height: 100%;
    }
    .singleOtherBottom {
        flex-basis: calc(24% - 8px);
    }
    footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: -moz-fit-content;
        min-height: -webkit-fit-content;
        min-height: fit-content;
        background: white;
        padding: 2em 1em;
        background-image: url(/static/media/water.0c9d79bd.png);
        background-repeat: no-repeat;
        background-size: 34%;
        background-position-y: 0em;
    }
    .footerLinks {
        list-style: none;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        padding: 0em 0em;
    }
    .copyrights {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        list-style: none;
        width: 100%;
        position: relative;
        top: 20px;
        font-size: 0.8em;
    }
    .footerLinks li {
        margin: 2.5em 1em;
        /* font-size: 0.8em; */
    }
    .navLinks {
        display: none;
    }
    #space {
        display: none;
        height: 17em;
    }
    #navMenuIcon {
        display: flex;
    }
    #lineTop,
    #lineMiddle,
    #lineBottom {
        height: 0.3vh;
        width: 27px;
    }
    #navDropMenu1 {
        display: none;
    }
    .lastestStory {
        flex-direction: row;
    }
    .storyLink {
        flex-direction: column;
        /* width:100%; */
    }
    .footerAddress {
        font-size: 0.9em;
    }
    #footerLogo {
        width: 45%;
        height: 50%;
    }
    .footerInner1 {
        padding-right: 4em;
    }
}

@media (max-width: 840px) {
    iframe {
        width: 600px;
    }
    .singleYear {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0em;
    }
    .yearsText {
        text-align: center;
        font-size: 1.8em;
        color: #deedfd;
        padding: 0em;
        padding-top: 1em;
    }
    .counterDiv {
        width: 7em;
    }
    .counterDiv h3 {
        font-size: 1em;
    }
    .borderRight {
        border-right-style: solid;
        height: 68%;
        border-right-color: #ffffff;
        padding-right: 0em;
        visibility: hidden;
    }
    .wholeServices {
        display: flex;
        justify-content: space-between;
        padding: 0em;
        flex-flow: row;
        padding-top: 3em;
    }
}

@media (max-width: 770px) {
    .ideas>h1 {
        font-size: 2em;
    }
    .ideas>p {
        font-size: 1.3em;
        width: 100%;
        line-height: 1.6em;
    }
    .slider {
        width: 100%;
        height: 18em;
    }
    .sliderBtn {
        display: block;
        order: 2;
        width: 100%;
        margin: 0 auto;
        margin-top: 6.2em;
    }
    .sliderBtn button {
        display: inline;
        width: 50%;
        box-shadow: rgba(117, 116, 116, 0.517);
        font-size: 1.4em;
        margin: 0 auto;
        padding: 1.1em;
        -webkit-filter: opacity(90%);
                filter: opacity(90%);
        background-color: whitesmoke;
    }
    .sliderbtm {
        display: flex;
        flex-direction: column;
        top: 100%;
        left: 0;
        width: 100%;
    }
    .sliderText {
        background-color: whitesmoke;
        order: 1;
        font-size: 1.4em;
        -webkit-filter: opacity(90%);
                filter: opacity(90%);
        width: 100%;
    }
    .latestStories {
        flex-flow: column;
        justify-content: space-evenly;
        margin: 0 1.2em;
        padding: 0.5em 0;
    }
    .latestStorieshead1>h1 {
        font-size: 2em;
        margin: 1em 0em 0em 0.5em;
    }
    .story {
        width: 100%;
        margin: 1em auto;
    }
    .storyimg {
        width: 100%;
        height: 25em;
    }
    .story h1 {
        font-size: 1.9em;
    }
    .story h2 {
        font-size: 1.6em;
    }
    .morebtn {
        margin: 2em auto;
        font-size: 1.2em;
    }
    .sliderLeftArrow {
        width: 0.9em;
        height: 0.9em;
    }
    .sliderRightArrow {
        width: 0.9em;
        height: 0.9em;
    }
}

@media (max-width: 768px) {
    
    .discImg img {
        width: 90%;
        height: 90%;
    }
    .singleOtherBottom {
        flex-basis: calc(24% - 8px);
    }
    #serPara {
        position: relative;
        top: -100em;
        content: "";
    }
    .footerLinks {
        list-style: none;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        padding: 0em 0em;
    }
    .wholeYears {
        display: flex;
        justify-content: space-around;
        margin: 1em;
        flex-direction: row;
        position: relative;
    }
    .years {
        /* font-family: "Roboto", sans-serif; */
        /* padding-top: 4em; */
        /* background-color: #00ad9f; */
        /* background: linear-gradient(#0d5899, #00ad9f); */
        padding: 1.1em 2em;
    }
    .yearsText {
        text-align: center;
        font-size: 1.2em;
        color: #deedfd;
        padding: 0em;
        padding-top: 1em;
    }
    .counterDiv {
        width: 100%;
    }
    .counterDiv h1 {
        font-size: 1.5em;
    }
    .counterDiv h3 {
        font-size: 0.7em;
    }
    ._404 {
        display: flex;
        font-size: 7em;
        justify-content: space-around;
        padding-top: 1em;
        text-shadow: rgb(0, 0, 0) 0px 0px 10px;
        color: white;
        width: 56%;
        align-items: center;
    }
    iframe {
        border: 0px;
        width: 500px;
    }
    /* hero header */
    .wrapper11 .thisTitle {
        display: block;
        position: absolute;
        top: 58%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        text-align: center;
        color: rgb(255, 255, 255);
        /* font-size: 1.3em; */
        text-shadow: 2px 2px #203d45;
        width: 77%;
    }
    .wrapper11 .thisTitle h1 {
        font-size: 2em;
        margin-bottom: 20px;
        
    }
    .wrapper11 .thisTitle p {
        font-size: 0.90em;}
    .wholeServices {
        display: flex;
        justify-content: space-between;
        padding: 3em;
        flex-flow: column;
    }
    .singleService {
        background-color: #f7f7f7;
        width: 100%;
        margin-bottom: 30px;
    }
    .homecontactDiv {
        width: 100%;
        flex-direction: column;
    }
    .aboutText p {
        /* padding-top: 2em; */
        color: rgb(76, 87, 97);
        line-height: 1.7em;
        /* padding-bottom: 3em; */
        text-align: left;
    }
    .contactInfo {
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        text-align: left;
        width: 100%;
        padding-right: 0em;
    }
    .contactInfo2 {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        text-align: left;
        width: 100%;
        padding-right: 0em;
    }
    .thisContactSection {
        display: flex;
        justify-content: center;
        padding: 3em 6em;
        width: 100%;
        flex-flow: column-reverse;
    }
    .contactForm h2 {
        line-height: 3.1em;
        font-size: 1.4em;
        color: #1e2d3b;
        text-align: center;
    }
    .heading h1 {
        font-size: 3.1em;
        text-align: center;
        line-height: normal;
        letter-spacing: 0.08em;
        color: #1e2d3b;
        font-weight: 600;
        padding-top: 2.2em;
    }
    .headingLeft h1 {
        font-size: 3.1em;
        text-align: center;
        line-height: normal;
        letter-spacing: 0.08em;
        color: #1e2d3b;
        font-weight: 600;
        padding-top: 3em;
    }
    .headingLeftCopyrights h1 {
        font-size: 3.1em;
        text-align: center;
        line-height: normal;
        letter-spacing: 0.08em;
        color: #1e2d3b;
        font-weight: 600;
        padding-top: 1em;
    }
    .story {
        /* width: 12em; */
        /* margin: 2.5em 0; */
        /* flex: 1 0 21%; */
        /* margin: 5px; */
    }
    .storyimg {
        width: 100%;
        height: 18em;
    }
}

@media (max-width: 767px) {
    .storyLink {
        flex-direction: column;
        width:100%;
        margin: 0 auto;
    }
    .wrapper11 .thisTitle .titleBorder{
        width:100px;
    }
    .ideasWrapper1 {
        margin-top: 0em;
    }
    .ideas {
        margin: 0em 1.5em -8em 1.5em;
    }
    .latestStorySection {
        margin: 0em 1.5em 0em 1.5em;
    }
    .Icon {
       
        width: 3.5em;
        height: 3.5em;
        line-height: 4.5em;
       
    }
}

@media screen and (max-width: 660px) {
    video {
        object-fit: cover;
        width: 100%;
        height: 100vh;
        position: absolute;
        z-index: -20;
        /* margin-top: 5em; */
    }
    .wrapper11 .thisTitle .titleBorder{
        width:100px;
    }
    .discImg {
        align-self: center;
    }
    .discImg img {
        width: 50%;
        height: 100%;
        padding-bottom: 1em;
    }
    .singleOtherBig {
        padding: 1em;
    }
    .singleOther {
        padding: 1em;
    }
    .singleOther1 {
        padding: 1em;
    }
    .singleOtherBottom {
        padding: 1em;
    }
    .wholeOther {
        flex-direction: column;
    }
    .wholeOtherReverse {
        flex-direction: column-reverse;
    }
    ._404 img {
        width: 59%;
        padding-left: 0.3em;
    }
    .wholeYears {
        display: flex;
        justify-content: space-around;
        margin: 1em;
        flex-direction: row;
        position: relative;
        left: 0em;
    }
    #navDropMenu1 {
        display: none;
    }
    #space {
        height: 11em;
    }
    #navLogo {
        width: 8em;
        padding: 0em 0;
    }
    .navDropUl li {
        font-size: 0.9em;
    }
    .footerAddress {
        font-size: 0.6em;
    }
    .footerLinks {
        font-size: 0.85em;
    }
    #footerLogo {
        width: 37%;
        height: 50%;
    }
    .articleImg {
        order: 2;
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 600px) {
    .wrapper11 .thisTitle .titleBorder{
        width:100px;
    }

    .storyLink:nth-child(3) div .story .storyimg,
    .storyLink:nth-child(5) div .story .storyimg {
        width: 72%;
        position: relative;
        right: -40px;
    }
    .footerLinks li {
        margin: 1.5em 1em;
        /* font-size: 0.8em; */
    }
    footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: -moz-fit-content;
        min-height: -webkit-fit-content;
        min-height: fit-content;
        background: white;
        padding: 2em 3.7em;
        background-image: url(/static/media/water.0c9d79bd.png);
        background-repeat: no-repeat;
        background-size: 75%;
        background-position-y: 1em;
    }
    .copyrights {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        list-style: none;
        width: 100%;
        position: relative;
        top: 20px;
        font-size: 0.7em;
    }
    .singleClient {
        display: flex;
        justify-content: center;
        width: 20%;
        padding-top: 4em;
        padding-bottom: 5em;
    }
    .footerInner1 {
        padding-right: 0em;
    }
    .footerLinks {
        list-style: none;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        padding: 0em 0em;
    }
    iframe {
        width: 300px;
        height: 300px;
    }
    .years {
        /* font-family: "Roboto", sans-serif; */
        /* padding-top: 4em; */
        /* background-color: #00ad9f; */
        /* background: linear-gradient(#0d5899, #00ad9f); */
        padding: 2.1em 0em;
    }
    .wholeYears {
        display: flex;
        justify-content: space-around;
        margin: 0em;
        margin-top: 1em;
        flex-direction: column;
        align-items: center;
    }
    .borderRight {
        border-right-style: solid;
        height: 68%;
        border-right-color: #ffffff;
        padding-right: 0em;
        visibility: hidden;
    }
    .singleYear {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0em;
        text-align: center;
        padding-bottom: 2em;
    }
    .contactForm p {
      
        padding: .5em;
      
    }
}

@media screen and (max-width: 538px) {
    #nav {
        padding:1em;
    }
    #navDropMenu1 {
        display: none;
    }
    .wrapper11 .thisTitle .titleBorder{
        width:100px;
    }
}

@media (max-width: 500px) {
    .wrapper11 .thisTitle .titleBorder{
        width:100px;
    }
    .wholeOtherReverse {
        padding-bottom: 0em;
    }
    .singleOtherBig {
        flex-direction: column;
    }
    .wholeTerms {
        padding: 3em 2em;
    }
    .wholePrivacy {
        padding: 3em 2em;
    }
    .copyrights {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        list-style: none;
        width: 100%;
        position: relative;
        top: 20px;
        font-size: 0.7em;
    }
    ._404 {
        display: flex;
        font-size: 4em;
        justify-content: space-around;
        padding-top: 1.8em;
        text-shadow: rgb(0, 0, 0) 0px 0px 10px;
        color: white;
        width: 30%;
    }
    .oops p {
        font-size: 1rem;
    }
    .oops h2 {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .wrapper11 .thisTitle .titleBorder{
        width:100px;
    }
    .wholeServices {
        display: flex;
        justify-content: space-between;
        padding: 1em;
        flex-flow: column;
    }
    .aboutText {
        padding-left: 1em;
        /* padding-right: 3em; */
    }
    .thisContactSection {
        display: flex;
        justify-content: center;
        padding: 3em 2em;
        width: 100%;
        flex-flow: column-reverse;
    }
    .lastestStory {
        flex-direction: column;
    }
    .story {
        width: 100%;
        margin: 1em auto;
    }
    .storyimg {
        width: 100%;
        height: 16em;
    }
    .counterDiv {
        width: 10em;
    }
    .counterDiv h3 {
        font-size: 1.4em;
    }
    .articleWrapper {
        margin: 0em 2em 0em 2em;
    }
    .article {
        flex-flow: column;
        padding-top: 0em;
    }
    .articlePage {
        display: flex;
        flex-direction: column;
    }
    .articleBtnsMobileUp {
        display: flex;
        justify-content: space-around;
        margin-top: 7em;
    }
    .backBtnUp {
        background-color: transparent;
        border-style: none;
        padding: 1em 0em;
        -webkit-filter: opacity(95%);
                filter: opacity(95%);
        font-size: 1em;
        font-weight: bolder;
    }
    .nextBtnUp {
        background-color: transparent;
        border-style: none;
        padding: 1em 0em;
        -webkit-filter: opacity(95%);
                filter: opacity(95%);
        font-size: 1em;
        font-weight: bolder;
        display: block;
    }
    .backBtnDown {
        background-color: transparent;
        border-style: none;
        padding: 1em 0em;
        -webkit-filter: opacity(95%);
                filter: opacity(95%);
        font-size: 1em;
        font-weight: bolder;
    }
    .nextBtnDown {
        background-color: transparent;
        border-style: none;
        padding: 1em 0em;
        -webkit-filter: opacity(95%);
                filter: opacity(95%);
        font-size: 1em;
        font-weight: bolder;
        display: block;
    }
    .backBtn,
    .nextBtn {
        display: none;
    }
    .articleHeader {
        order: 3;
        font-size: 81%;
        width: 24em;
    }
    .articleHeader h1 {
        font-size: 2em;
        font-weight: lighter;
    }
    .articleHeader h2 {
        margin-left: 0.2em;
        font-size: 1.2em;
        font-weight: lighter;
    }
    .articleHeader a {
        font-size: 1em;
        padding-left: 0.3em;
        text-decoration: none;
        color: darkgreen;
    }
    .articleParagraph {
        order: 4;
        display: block;
        background-color: antiquewhite;
        width: 100%;
        padding: 2em 2em;
        margin-top: 0.5em;
        line-height: 1.5em;
    }
    .articleParagraph p {
        font-size: 1em;
    }
    .homecontactDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 40vh;
        font-family: Arial, Helvetica, sans-serif;
        background: white;
        width: 100%;
    }
    .homeContacUsImg {
        display: none;
    }
}

@media (max-width: 460px) {
    .wrapper11 .thisTitle .titleBorder{
        width:100px;
    }
    .ideas {
        margin: 0em 1.5em 0 1.5em;
    }
    .latestStorySection {
        margin: 0em 1.5em 0 1.5em;
    }
    .ideasWrapper1 {
        margin-top: 0em;
    }
    .services h1 {
        font-size: 1.5rem;
        font-weight: 100;
        padding: 0.7em 0;
        padding-top: 1.3em;
        color: #2b292d;
        padding-bottom: 0;
    }
    .ideas>h1 {
        font-size: 1.5em;
    }
    .ideas>p {
        font-size: 0.8em;
        width: 100%;
        line-height: 1.5em;
    }
    .latestStories {
        flex-flow: column;
        justify-content: space-evenly;
        margin: 0;
        padding: 0.5em 0;
    }
    .latestStorieshead1>h1 {
        font-size: 1.5em;
        margin: 1em 0em 0em 0em;
    }
    .story {
        width: 100%;
        margin: 1em auto;
    }
    .storyimg {
        width: 100%;
        height: 21em;
    }
    .story h1 {
        font-size: 1.2em;
    }
    .story h2 {
        font-size: 0.9em;
    }
    .morebtn {
        font-size: 1em;
        margin: 2em auto;
    }
}

@media (max-width: 425px) {
    video {
        object-fit: cover;
        width: 100%;
        height: 100vh;
        position: absolute;
        z-index: -20;
        /* margin-top: 5em; */
    }
    .wrapper11 .thisTitle .titleBorder{
        width:100px;
    }
    .singleClient {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 0em;
        padding-bottom: 0em;
    }
    .singleClient:nth-child(5) {
        padding-bottom: 3em;
    }
    .wholeClients {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 3em;
    }
    .yearsText {
        text-align: center;
        font-size: 1.2em;
        color: #deedfd;
        padding: 0em;
        padding-top: 2em;
    }
    .copyrights {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        list-style: none;
        width: 100%;
        position: relative;
        top: 20px;
        font-size: 0.6em;
    }
    ._404 img {
        width: 94%;
        padding-left: 0.3em;
    }
    #navDropMenu1 {
        display: none;
    }
    iframe {
        border: 0px;
        width: 300px;
    }
    .wrapper11 .thisTitle {
        display: block;
        position: absolute;
        top: 58%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        text-align: center;
        color: rgb(255, 255, 255);
        /* font-size: 0.8em; */
        text-shadow: 2px 2px #203d45;
        width: 100%;
    }
    .heading h2 {
        font-size: 2.1em;
        text-align: center;
        line-height: normal;
        letter-spacing: 0.08em;
        color: #1e2d3b;
        font-weight: 600;
        padding-top: 3em;
    }
    .headingLeft h2 {
        font-size: 2.1em;
        padding-top: 1em;
    }
    .headingLeft img, .heading img {
        height: 50px;}
        #ourProducts .heading img{
            height:52px;
        }
    .headingLeftCopyrights h1 {
        font-size: 1.6em;
        padding-top: 2em;
    }
    .borderBottom {
        border-bottom-style: solid;
        width: 9%;
        /* text-align: center; */
        /* align-self: center; */
        width: 83px;
        border-width: 8px;
        padding-top: 1em;
        border-bottom-color: #00ad9f;
    }
    .ideasWrapper1 {
        padding: 1em 0;
        background-color: #fff;
    }
    .wholeServices {
        display: flex;
        justify-content: space-between;
        padding: 0em;
        flex-flow: column;
        padding-top: 2em;
    }
    .aboutText {
        padding-left: 1em;
        padding-right: 0.5em;
    }
    .singleClient img,
    .singleClient img:hover {
        width: 13em;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        /* height: 152px; */
        height: 9em;
    }
    .singleClient img {
        -webkit-filter: grayscale(1);
                filter: grayscale(1);
    }
    .singleClient img:hover {
        -webkit-filter: grayscale(0);
                filter: grayscale(0);
    }
    .thisContactSection {
        display: flex;
        justify-content: center;
        padding: 3em 2em;
        width: 100%;
        flex-flow: column-reverse;
    }
    .services {
        margin: 0 1em;
        padding: 3em 0;
    }
    footer {
        padding: 2em 0.7em;
    }
    input, textarea {
        width: 100%;
    }
    .contactForm button{
        margin-right: 0;
    }
}

@media (max-width: 412px) {
    .ideas {
        margin-bottom: 0;
    }
    .latestStorieshead1>h1 {
        margin: 1em 0em 0em 0em;
        font-size: 1.5em;
    }
    .wrapper11 .thisTitle .titleBorder{
        width:100px;
    }
}

@media (max-width: 375px) {
    .storyimg {
        height: 21em;
    }
    footer {
        background-size: 100%;
    }
    .counterDiv h3 {
        color: #deedfd;
    }
}

@media (max-width: 320px) {
    .storyimg {
        height: 17em;
    }
    .copyrights {
        font-size: 0.4em;
    }
    ._404 img {
        width: 100%;
        padding-left: 0.3em;
    }
    .services {
        margin: 0 1em;
        padding: 3em 0;
    }
    .aboutText {
        padding-left: 1em;
        padding-right: 0.5em;
    }
    .aboutText p {
        /* padding-top: 2em; */
        color: rgb(76, 87, 97);
        line-height: 1.7em;
        padding-bottom: 1em;
        text-align: left;
        font-size: 0.8em;
    }
    .counterDiv h3 {
        font-size: 1.3em;
    }
    .articleImg {
        order: 2;
        width: 100%;
        height: 230px;
    }
    .articleHeader {
        order: 3;
        font-size: 70%;
        width: 24em;
    }
    
}
